import * as React from "react"
import '../css/main.css'
import '../css/bootstrap.min.css'
import '../css/animate.compat.css'
import '../css/boxicons.min.css'
import '../css/icofont.min.css'
import '../css/owl.carousel.min.css'

import Head from '../components/head'
import HeaderComponent from "../components/header.js"
import useScript from "../components/useScript"
import FooterComponent from "../components/footer"

// markup
const PaaSPage = () => {
  
    useScript('../../static/js/jquery.min.js')
    useScript('../../static/js/bootstrap.bundle.js')
    useScript('../../static/js/jquery.easing.min.js')
    useScript('../../static/js/venobox.min.js')
    useScript('../../static/js/jquery.waypoints.min.js')
    useScript('../../static/js/counterup.min.js')
    useScript('../../static/js/owl.carousel.min.js')
    useScript('../../static/js/isotope.pkgd.min.js')
    useScript('../../static/js/aos.js')
    useScript('../../static/js/main.js')

  return (
    <>
    <Head title={'Crowdfunding-as-a-Service'}/>
    <header id="header" className="fixed-top">
        <HeaderComponent />
    </header>
    <main id='main'>
        <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                <h2>Products</h2>
                <ol>
                    <li><a href="/">Home</a></li>
                    <li>Products</li>
                </ol>
                </div>
            </div>
        </section>
        <section className="services">
            <div className="container">
                <div className="row">
                    <div className="section-title">
                        <h2>Crowdfunding-as-a-Service</h2>
                        <p>
                            Launch your Crowdfunding/P2P lending or Loan Market platform today with Kvotum and accelerate your business. We offer a highly advanced software solution and a service package to launch your own funding without the hassle of building or acquiring your own system.
                        </p>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up">
                        <div className="icon-box icon-box-pink">
                            <div className="icon"><i className="bx bx-chip"></i></div>
                            <h4 className="title">Software</h4>
                            <p className="description">
                                Fully branded web application designed for an easy-to-operate investment workflow
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon-box icon-box-cyan">
                            <div className="icon"><i className="bx bx-file"></i></div>
                            <h4 className="title">Legal</h4>
                            <p className="description">
                                Full package of operational legal documentation, SPV registration and more
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                        <div className="icon-box icon-box-green">
                            <div className="icon"><i className="bx bx-tachometer"></i></div>
                            <h4 className="title">Marketing</h4>
                            <p className="description">
                                Organization and management of paid advertising channels, referral programs and social media
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                        <div className="icon-box icon-box-blue">
                            <div className="icon"><i className="bx bx-support"></i></div>
                            <h4 className="title">Support</h4>
                            <p className="description">
                                High-availability technical and advisory support during the whole partnership lifecycle
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 video-box">
                        <img src="../../static/product_header.jpeg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center p-5">
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title">Focus on your business</h4>
                            <p className="description">
                                Generate financing while avoiding the complexity of traditional means. Crowdfunding/P2P Lending is the most modern approach to business loan financing, which allows businesses to enjoy unprecedent flexibility and growth.
                            </p>
                        </div>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-gift"></i></div>
                            <h4 className="title">Acquire new customers</h4>
                            <p className="description">
                                Your investors can become your future customers - by advertising your projects and/or services during development you will create a pre-existing potential customer base and marketing channels
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="service-details">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                        <div className="card">
                            <div className="card-img">
                            <img src="../../static/build.jpg" alt="Brand development" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Build a stronger brand</h5>
                                <p className="card-text">
                                    Crowdfunding/P2P Lending is not only an efficient financing instrument, but also a great opportunity to communicate your brand and create a bond with your potential clients
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                        <div className="card">
                            <div className="card-img">
                                <img src="../../static/reach.jpg" alt="Marketing" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Reach greater audience</h5>
                                <p className="card-text">
                                    Our team holds more than 10 years of total experience in collective loan marketing. We have existing channels to promote your projects with a wide influencer network, algorithms to advertise on paid channels and much more..
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                        <div className="card">
                            <div className="card-img">
                                <img src="../../static/office.jpg" alt="Less risk" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Avoid competition and risk</h5>
                                <p className="card-text">
                                    By creating your own Crowdfunding/P2P Lending platform - you distance yourself not only from potentially competing projects, but also from the downsides of marketplace reputation as well. Keep control of both your business and your reputation
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                        <div className="card">
                            <div className="card-img">
                                <img src="../../static/computer.jpg" alt="..." />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Save costs</h5>
                                <p className="card-text">
                                    Creating and supporting a complex web application is costly. We remove the burden from you by handling development, regular updates, hosting and other operational expenses
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="pricing section-bg" data-aos="fade-up">
            <div className="container">
                <div className="section-title">
                    <h2>Packages</h2>
                    <p>We offer our services in several standard packages. They are designed to cover most use-cases, but we can also design tailored solutions. <a href='/contact'>Get in touch</a> with us to book a demo and talk.</p>
                </div>
                <div className="row">
                    <div className="col-lg-4 box">
                        <h3>Starter</h3>
                        <ul>
                            <li><i className="bx bx-check"></i> Access to the web application</li>
                            <li><i className="bx bx-check"></i> One legal entity</li>
                            <li><i className="bx bx-x"></i>Limited legal support</li>
                            <li><i className="bx bx-x"></i>No marketing support</li>
                            <li><i className="bx bx-x"></i>Limited technical support</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 box featured">
                        <h3>Partnership</h3>
                        <ul>
                            <li><i className="bx bx-check"></i> Full access to the web application</li>
                            <li><i className="bx bx-check"></i> One legal entity</li>
                            <li><i className="bx bx-check"></i> Unlimited projects and customers</li>
                            <li><i className="bx bx-check"></i> Legal and Marketing package included</li>
                            <li><i className="bx bx-check"></i> Priority technical support</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 box">
                        <h3>Enterprise</h3>
                        <ul>
                            <li><i className="bx bx-check"></i> Everything that you get with Partnership and...</li>
                            <li><i className="bx bx-check"></i> Unlimited legal entities</li>
                            <li><i className="bx bx-check"></i> Self-hosted option</li>
                            <li><i className="bx bx-check"></i> Custom workflow development</li>
                            <li><i className="bx bx-check"></i> Custom integrations</li>
                        </ul>
                    </div>
                </div>
                <div className="section-title pt-5">
                    <a href="/contact" class="get-started-btn">Contact us</a>
                </div>
            </div>
        </section>
    </main>
    <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
        <FooterComponent />
    </footer>
    </>
  )
}

export default PaaSPage